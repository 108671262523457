@font-face {
	font-family: "RingsideCompressedSSm Medium A";
	font-style: normal;
	font-weight: normal;
	src: url("../../fonts/RingsideCompressedSSm-Medium-TD-Space.woff");
}

@font-face {
	font-family: "RingsideCompressedSSm Medium B";
	font-style: normal;
	font-weight: normal;
	src: url("../../fonts/RingsideCompressedSSm-Medium-TD.woff");
}

@keyframes mainMoveIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

button {
	appearance: none;
	border: none;
	outline: none;
}

.main {
	align-content: stretch;
	align-items: center;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: mainMoveIn;
	animation-timing-function: ease-out;
	background-color: rgb(51, 65, 120);
	bottom: 0;
	display: flex;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	flex-flow: column nowrap;
	justify-content: center;
	left: 0;
	overflow: hidden;
	padding-top: 24px;
	position: fixed;
	right: 0;
	top: 0;
	will-change: opacity;
}

@media (min-width: 375px) {
	.main {
		padding-top: 30px;
	}
}

@media (min-width: 1024px) and (orientation: landscape) {
	.main {
		padding-top: 40px;
	}
}

.name {
	display: block;
}

svg * {
	transition:
		fill .1s ease-out,
		opacity .1s ease-out;
}

.logo {
	fill: rgb(255, 181, 0);
	flex: 1;
	position: relative;
	transform: translate3d(-1vmin, 0, 0);
	width: 120vmin;
	z-index: 2;
}

@media (min-width: 414px) and (orientation: landscape) {
	.logo {
		width: 80vmin;
	}
}

@media (min-width: 595px) and (orientation: portrait) {
	.logo {
		width: 80vmin;
	}
}

@keyframes michaelFaceMoveIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

/** Font face animation **/
:global(#michael) path {
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: michaelFaceMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	will-change: opacity, transform;
}

:global(#michael) path:nth-child(1) { animation-delay: 1300ms; }
:global(#michael) path:nth-child(2) { animation-delay: 1350ms; }
:global(#michael) path:nth-child(3) { animation-delay: 1400ms; }
:global(#michael) path:nth-child(4) { animation-delay: 1450ms; }
:global(#michael) path:nth-child(5) { animation-delay: 1500ms; }
:global(#michael) path:nth-child(6) { animation-delay: 1550ms; }
:global(#michael) path:nth-child(7) { animation-delay: 1600ms; }

@keyframes jamesMoveIn {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

:global(#james) path {
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: jamesMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	will-change: opacity, transform;
}

:global(#james) path:nth-child(1) { animation-delay: 1300ms; }
:global(#james) path:nth-child(2) { animation-delay: 1350ms; }
:global(#james) path:nth-child(3) { animation-delay: 1400ms; }
:global(#james) path:nth-child(4) { animation-delay: 1450ms; }
:global(#james) path:nth-child(5) { animation-delay: 1500ms; }

/** Font shadow animation **/
:global(#Shadow-James) path {
	/* animation-delay: 1550ms;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: jamesShadowMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1); */
	display: none;
	fill: black;
	will-change: opacity, transform;
}

@keyframes ampersandMoveIn {
	from {
		opacity: 0;
		transform: scale(0.6);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

:global(#ampersand) {
	animation-delay: 1400ms;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: ampersandMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	transform-origin: center;
	will-change: opacity, transform;
}

@keyframes sidebarsMoveIn {
	from {
		opacity: 0;
		transform: rotate(-5deg);
	}

	to {
		opacity: 1;
		transform: rotate(0);
	}
}

:global(#sidebars) {
	animation-delay: 1400ms;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: sidebarsMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	display: none;
	transform-origin: center;
	will-change: opacity, transform;
}

@media (min-width: 595px) and (orientation: portrait) {
	:global(#sidebars) {
		display: block;
	}
}

@keyframes theMoveIn {
	from {
		opacity: 0;
		transform: translate3d(0, -24px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

:global(#the) {
	animation-delay: 1550ms;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: theMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	transform-origin: center;
	will-change: opacity, transform;
}

@keyframes showMoveIn {
	from {
		opacity: 0;
		transform: translate3d(0, 24px, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

:global(#show) {
	animation-delay: 1550ms;
	animation-duration: 350ms;
	animation-fill-mode: both;
	animation-name: showMoveIn;
	animation-timing-function: cubic-bezier(.33, .13, .11, 1);
	transform-origin: center;
	will-change: opacity, transform;
}

.siteFooter {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 0 30px;
	position: relative;
	text-align: center;
	width: 100%;
	z-index: 1;
}

@media (min-width: 375px) and (orientation: portrait) {
	.siteFooter {
		font-size: 32px;
		padding: 0 0 40px;
	}
}

@media (min-width: 375px) and (orientation: landscape) {
	.siteFooter {
		padding: 0 0 20px;
	}
}

@media (min-width: 595px) and (orientation: portrait) {
	.siteFooter {
		font-size: 40px;
	}
}

@media (min-width: 1024px) and (orientation: landscape) {
	.siteFooter {
		font-size: 40px;
		padding: 30px 30px 60px;
	}
}

.button {
	border-radius: 50px;
	box-shadow: 0 0 0 2px white;
	cursor: pointer;
	display: inline-block;
	font-family: "RingsideCompressedSSm Medium A", "RingsideCompressedSSm Medium B", Courier;
	font-size: 14px;
	letter-spacing: -0.01em;
	margin: 8px 0;
	padding: 12px 18px;
	text-align: center;
	text-decoration: none;
}

@media (min-width: 375px) and (orientation: landscape) {
	.button {
		font-size: 16px;
	}
}

@media (min-width: 375px) and (orientation: portrait) {
	.button {
		font-size: 16px;
	}
}

@media (min-width: 595px) and (orientation: landscape) {
	.button {
		font-size: 20px;
	}
}

@media (min-width: 595px) and (orientation: portrait) {
	.button {
		font-size: 20px;
	}
}

@keyframes emailWallMoveIn {
	from { opacity: 0; }
	to { opacity: 0.96; }
}

.emailWall {
	align-content: stretch;
	align-items: center;
	animation-duration: 700ms;
	animation-fill-mode: both;
	animation-name: emailWallMoveIn;
	animation-timing-function: ease-out;
	background-color: green;
	bottom: 0;
	color: white;
	display: flex;
	flex-flow: column nowrap;
	font-size: 2em;
	justify-content: center;
	left: 0;
	padding: 0 30vw;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
}

.question {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	width: 800px;
}

.question--active {
	composes: question;
	opacity: 1;
	pointer-events: auto;
}

.questionButtons {
	align-content: stretch;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	padding-top: 1.5em;
}

.questionButton {
	appearance: none;
	background-color: transparent;
	border: none;
	border-radius: 0.5em;
	box-shadow: 0 0 0 1px white;
	color: inherit;
	cursor: pointer;
	font: inherit;
	margin: 0;
	min-width: 5em;
	outline: none;
	padding: 1vmin;
}

.questionButton + .questionButton { margin-left: 24px; }

.questionInput {
	appearance: none;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid white;
	border-radius: 0;
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
	padding: 1vmin 0;
	width: 100%;
}

.guy {
	height: 100vmin;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: fixed;
	top: 50%;
	width: 100vmin;
	z-index: 1;
}

.guy img {
	height: auto;
	width: 100%;
}

.guy--james {
	composes: guy;
	border-bottom-right-radius: 50%;
	border-top-right-radius: 50%;
	left: 0;
	transform: translate3d(-40%, -50%, 0);
}

.guy--michael {
	composes: guy;
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
	right: 0;
	transform: translate3d(40%, -50%, 0);
}

@keyframes fadeVideo {
	from { opacity: 0; }
	to { opacity: 1; }
}

.videoWall {
	align-items: center;
	background-image: radial-gradient(0, 255);
	background-image: radial-gradient(rgb(31, 44, 90), rgb(37, 69, 164));
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	transform: translate3d(-100%, 0, 0);
	z-index: 2;
}

.videoWall::after {
	content: "CLOSE";
	cursor: pointer;
	font-family: "RingsideCompressedSSm Medium A", "RingsideCompressedSSm Medium B", Courier;
	font-size: 24px;
	position: absolute;
	right: 30px;
	top: 24px;
}

.videoWall--open {
	animation-duration: 300ms;
	animation-fill-mode: both;
	animation-name: fadeVideo;
	transform: translate3d(0, 0, 0);
}

.videoAspectRatio {
	display: block;
	height: 315px;
	margin: 0;
	position: relative;
	width: 560px;

	height: calc(315 * 0.5 * 1px);
	width: calc(560 * 0.5 * 1px);
}

@media (min-width: 620px) {
	.videoAspectRatio {
		height: 315px;
		margin: 0;
		position: relative;
		width: 560px;
	}
}

@media (min-width: 950px) {
	.videoAspectRatio {
		height: calc(315 * 1.5 * 1px);
		margin: 0;
		position: relative;
		width: calc(560 * 1.5 * 1px);
	}
}

.video {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
